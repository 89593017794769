import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  :root {
    --black: #2d2a32;
    --white: #fff;
    --off-white: #fafdf6;
    --yellow: #ffda54;
    --font-primary: 'Montserrat', sans-serif;
  }

  *,
  *::before,
  *::after { box-sizing: border-box; }

  body {
    margin: 0;
    background: var(--off-white);
    color: var(--black);
    font-family: var(--font-primary);
    font-size: 16px;
    line-height: 1.5;
	}

  h1,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  h2 {
    margin-bottom: 16px;
  }

  img { max-width: 100%; }

  figure { margin: 0; }

  form { position: relative; }

  /* Focus management */

  [role="tabpanel"],
  button,
  a {
    outline: none;

    &::-moz-focus-inner { border: 0; }
  }

  button,
  a {
    color: var(--black);

    &:not(:disabled) { cursor: pointer; }
  }
`;
export default GlobalStyle;
