import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import screen from 'superior-mq';
import { bp } from '../styles/helpers';
import Container from './Container';
import HeroForm from './HeroForm';

const StyledHero = styled.section`
  height: 100%;

  ${screen.above(bp.mobile, `
    height: calc(100vh - 78px);
  `)}

  ${screen.below(bp.tablet, `
    margin-top: 45px;
  `)}

  ${screen.below(bp.mobile, `
    padding-bottom: 20px;
  `)}
`;

const HeroContainer = styled(Container)`
  height: 100%;

  ${screen.above(bp.mobileSm, `
    display: flex;
    align-items: center;
  `)}
`;

const ContentWrap = styled.div`
  width: 100%;
  display: flex;

  ${screen.above(bp.tablet, `
    align-items: flex-end;
    justify-content: center;
  `)}

  ${screen.below(bp.tablet, `
    flex-direction: column;
    align-items: center;
  `)}

  ${screen.below('430px', `
    align-items: flex-start;
  `)}
  
`;

const HeroHeading = styled.h2`
  font-size: ${rem(200)};
  font-weight: 400;
  line-height: 1;
  margin-right: 160px;

  ${screen.below(bp.desktopSm, `
    font-size: ${rem(160)};
    margin-right: 120px;
  `)};

  ${screen.below(bp.laptopSm, `
    font-size: ${rem(128)};
    margin-right: 80px;
  `)};

  ${screen.below(bp.mobile, `
    font-size: ${rem(96)};
    margin-right: 0;
  `)};

  ${screen.below(bp.mobileSm, `
    font-weight: 500;
    font-size: ${rem(75)};
  `)}
`;

const ThanksHeading = styled.h2`
  text-align: center;
  font-size: ${rem(160)};
  font-weight: 400;

  ${screen.below(bp.tablet, `
    font-size: ${rem(128)};
  `)}

  ${screen.below(bp.mobile, `
    font-size: ${rem(96)};
    line-height: 1;
  `)}

  span {
    display: block;
    font-size: ${rem(22)};
    margin-top: 20px;

    ${screen.below(bp.mobile, `
      font-size: ${rem(18)};
      margin-top: 35px;
    `)}
  }
`;

const Hero = (props) => {
  const [formComplete, setFormComplete] = useState(false);

  return (
  <StyledHero>
    <HeroContainer>
      <ContentWrap>
        {formComplete
          ? <ThanksHeading>thank you! <span>I'll be in touch soon.</span></ThanksHeading>
          : <Fragment>
              <HeroHeading>hello.</HeroHeading>
              <HeroForm setFormComplete={setFormComplete} />
            </Fragment>
        }    
        
      </ContentWrap>
    </HeroContainer>
  </StyledHero>
  );
};

export default Hero;
