import React from 'react';
import Hero from '../components/Hero';

const Home = () => {
	return (
		<Hero />
	);
};

export default Home;
