import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import screen from "superior-mq";
import { bp } from "../styles/helpers";
import Container from "../components/Container";

const ResumeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  column-gap: 1px;
  background: var(--black);
  margin: 90px 0;

  ${screen.below(
    bp.tablet,
    `
    grid-template-columns: 1fr;
    margin: 45px 0;
  `
  )}
`;

const ResumeSection = styled.section`
  --column-pad: 30px;
  background: var(--off-white);
  padding-right: var(--column-pad);

  ${screen.below(
    bp.tablet,
    `
    --column-pad: 0;
  `
  )}

  ${(props) =>
    props.type === "about" &&
    `
    order: 1;

    ${screen.above(
      bp.tablet,
      `
      grid-column: 1;
      grid-row: 1;
    `
    )}
  `}

  ${(props) =>
    props.type === "skills" &&
    `
    order: 3;

    h3 {
      margin-bottom: -5px;
    }

    ${screen.above(
      bp.tablet,
      `
      grid-column: 1;
      grid-row: 2;
    `
    )}

    & > section {
      &:first-of-type {
        margin-bottom: 25px;
      }
    }
  `}

  ${(props) =>
    props.type === "education" &&
    `
    order: 4;

    h3 {
      margin-bottom: -5px;
    }

    ${screen.above(
      bp.tablet,
      `
      grid-column: 1;
      grid-row: 3;
    `
    )}
  `}

  ${(props) =>
    props.type === "experience" &&
    `
    order: 2;

    ${screen.above(
      bp.tablet,
      `
      grid-column: 2;
      grid-row: 1 / span 3;
    `
    )}
  `}

  &:last-of-type {
    padding-right: 0;
    padding-left: var(--column-pad);
  }
`;

const EducationDuration = styled.p`
  margin-bottom: -10px;
`;

const JobListing = styled.section`
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 25px;
  }
`;

const JobTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${screen.below(
    bp.mobile,
    `
    display: block;
  `
  )}
`;

const JobDuration = styled.span`
  display: block;
  font-size: ${rem(14)};
  font-weight: 400;
`;

const DoubleDuration = styled.div`
  ${screen.above(
    bp.mobile,
    `
    position: absolute;
    top: 5px;
    right: 0;
    text-align: right;
  `
  )}
`;

const JobDuties = styled.ul`
  ${screen.below(
    bp.tablet,
    `
    padding-left: 15px;
  `
  )}
`;

const DutyItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const JobLocation = styled.p`
  margin-top: 3px;

  ${screen.below(
    bp.tablet,
    `
    font-size: ${rem(14)};
  `
  )}
`;

const About = () => {
  return (
    <article>
      <Container>
        <ResumeGrid>
          <ResumeSection type="about">
            <h2>about</h2>
            <div>
              <p>
                I am a senior level front end developer with over 4 years of
                experience building custom React and WordPress sites. As a
                developer I strive to build complex designs with pixel perfect
                accuracy, without compromising ADA compliance. As a leader I aim
                to create a cohesive and efficient team through mentorship and
                collaboration.
              </p>

              {/* <p>I am looking to start a new phase in my career where I can continue to challenge myself as a developer, and get more opportunities to grow not only as a leader, but with a company.</p> */}
            </div>
          </ResumeSection>

          <ResumeSection type="skills">
            <h2>key skills</h2>

            <section>
              <h3>Languages and Frameworks</h3>
              <p>
                HTML, CSS, Javascript, jQuery, Typescript, PHP, React, Liquid
              </p>
            </section>

            <section>
              <h3>Tools</h3>
              <p>
                Git (GitHub, GitLab), Sanity, WordPress, Advanced Custom Fields,
                WP Engine, Netlify, Buddy, Contentful, Prismic, Adobe Photoshop,
                Adobe Illlustrator, Sketch, Figma
              </p>
            </section>
          </ResumeSection>

          <ResumeSection type="education">
            <h2>education</h2>

            <section>
              <h3>Northern Michigan University</h3>
              <EducationDuration>August 2016 - May 2018</EducationDuration>
              <p>
                Completed 47 credits towards B.S. in Computer Science and Minor
                in Mathematics
              </p>
            </section>

            <section>
              <h3>University of Michigan</h3>
              <EducationDuration>September 2011 - May 2015</EducationDuration>
              <p>
                B.A. in Communication Studies and a Minor in Global Media
                Studies
              </p>
            </section>
          </ResumeSection>

          <ResumeSection type="experience">
            <h2>experience</h2>
            <JobListing>
              <JobTitle>
                Senior Developer
                <JobDuration>Sept 2022 - Present [Remote]</JobDuration>
              </JobTitle>
              <JobLocation>BASIC/DEPT&reg;</JobLocation>
            </JobListing>

            <JobListing>
              <JobTitle>
                Lead Front End Developer
                <JobDuration>Jan 2022 - Sept 2022 [Remote]</JobDuration>
              </JobTitle>
              <JobLocation>Elegant Seagulls</JobLocation>
              <JobDuties>
                <DutyItem>
                  Spearhead plans to create documentation and develop a cohesive
                  starter kit to align development team and onboard new team
                  members.
                </DutyItem>
                <DutyItem>
                  Evaluate new client requests and provide recommendations on
                  the scope, scale, and developer resources required to
                  implement.
                </DutyItem>
                <DutyItem>
                  Manage daily DevOps duties for a large enterprise client with
                  weekly, post-launch, site improvement tasks.
                </DutyItem>
                <DutyItem>
                  Collaborate with Technical Director to plan and present
                  bi-monthly instructional meetings to a 8-member developer
                  team.
                </DutyItem>
              </JobDuties>
            </JobListing>

            <JobListing>
              <JobTitle>
                Senior Front End Developer
                <JobDuration>Nov 2021 - Jan 2022 [Remote]</JobDuration>
              </JobTitle>
              <JobLocation>Elegant Seagulls</JobLocation>
              <JobDuties>
                <DutyItem>
                  Led development of a headless React site with over 20 unique
                  page template experiences, including a custom resource center
                  with gated content, with a team of 3 developers.
                </DutyItem>
                <DutyItem>
                  Communicated with clients and stakeholders during the build
                  process, to ensure their current business requirements are
                  met.
                </DutyItem>
                <DutyItem>
                  Provided guidance and assistance to development team members.
                </DutyItem>
              </JobDuties>
            </JobListing>

            <JobListing>
              <JobTitle>
                Front End Developer
                <DoubleDuration>
                  <JobDuration>Aug 2020 - Nov 2021 [Remote]</JobDuration>
                  <JobDuration>
                    Sept 2018 - Aug 2020 [Marquette, MI]
                  </JobDuration>
                </DoubleDuration>
              </JobTitle>
              <JobLocation>Elegant Seagulls</JobLocation>
              <JobDuties>
                <DutyItem>
                  Resolved over 200 accessibility issues related to a client’s
                  legacy Shopify theme, by revamping and rebuilding core
                  aspects.
                </DutyItem>
                <DutyItem>
                  Built multiple dynamic, custom page templates, using React
                  configured with Contentful, within an existing complex code
                  base.
                </DutyItem>
                <DutyItem>
                  Self-managed multiple full site builds, with coaching and
                  guidance from Technical Director.
                </DutyItem>
                <DutyItem>
                  Developed custom themes using both traditional and headless
                  content management systems, likeWordPress, Contentful and
                  Prismic.
                </DutyItem>
              </JobDuties>
            </JobListing>

            <JobListing>
              <JobTitle>
                Development and Design Intern
                <JobDuration>Jan 2018 - Sept 2018 [Marquette, MI]</JobDuration>
              </JobTitle>
              <JobLocation>Elegant Seagulls</JobLocation>
              <JobDuties>
                <DutyItem>
                  Implemented annual rebrand of a local ski marathon website.
                </DutyItem>
                <DutyItem>
                  Fixed bugs from existing websites and implemented enhancements
                  that significantly improved web{" "}
                </DutyItem>
                functionality and speed.
                <DutyItem>
                  Created mobile designs that were cohesive with existing
                  desktop style guidelines, as well as mindful of technical
                  feasibility.
                </DutyItem>
                <DutyItem>
                  Contributed designs and provided feedback during design
                  request sprints.
                </DutyItem>
              </JobDuties>
            </JobListing>
          </ResumeSection>
        </ResumeGrid>
      </Container>
    </article>
  );
};

export default About;
