import styled from 'styled-components';
import screen from 'superior-mq';
import { bp } from '../styles/helpers';

const Container = styled.div`
  padding: 0 20px;
  max-width: 1340px;
  margin: auto;

  ${screen.below(bp.desktopSm, `
    max-width: 1080px;
    padding: 0 50px;
  `)}

  ${screen.below(bp.mobile, `
    max-width: 580px;
    padding: 0 15px;
  `)}
`;

export default Container;
