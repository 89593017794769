import React from 'react';
import styled from 'styled-components';
import { rem, size } from 'polished';
import { Link } from "react-router-dom";
import screen from 'superior-mq';
import { bp } from '../styles/helpers';
import VisuallyHidden from './VisuallyHidden';
import Container from './Container';
import Logo from './Logo';

const StyledHeader = styled.header`
  padding: 20px 0;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  ${size('38px', '147px')}
  display: block;
  color: var(--yellow);
`;

const ResumeLink = styled(Link)`
  position: relative;
  font-size: ${rem(16)};
  color: var(--black);
  transition: color 150ms ease 150ms;

  ${screen.below(bp.tablet, `
    margin-right: 25px;
  `)}

  &::before {
    ${size('calc(100% + 20px)', 'calc(100% + 50px)')}
    position: absolute;
    top: -10px;
    left: -25px;
    content: "";
    background: var(--black);
    transform: scaleY(0);
    transition: transform 300ms ease;
    transform-origin: center bottom;
    z-index: -1;
  }

  &:hover {
    color: var(--white);

    &::before {
      transform: scaleY(1);
    }
  }
`;

const Header = (props) => (
  <StyledHeader>
    <StyledContainer>
      <h1>
        <VisuallyHidden>Kimber Guidebeck</VisuallyHidden>
        <Link to="/"><StyledLogo /></Link>
      </h1>

      <ResumeLink to="/resume">resume</ResumeLink>
    </StyledContainer>
  </StyledHeader>
);

export default Header;
