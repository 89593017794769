import React from 'react';
import styled from 'styled-components';
import { rem, size } from 'polished';
import screen from 'superior-mq';
import { Formik } from 'formik';
import { bp } from '../styles/helpers';
import VisuallyHidden from './VisuallyHidden';
import netlifySubmit from '../util/netlifySubmit';

const StyledForm = styled.form`
  width: 445px;
  margin-bottom: 27px;

  ${screen.below(bp.tablet, `
    width: 100%;
    max-width: 400px;
    margin: 45px 0 0;
  `)}

  ${screen.below(bp.portrait, `
    margin: 45px auto 0;
  `)}

  ${screen.below(bp.mobileSm, `
    margin-top: 15px;
  `)}
`;

const FormInput = styled.div`
  margin-bottom: 30px;
  position: relative;

  ${screen.below(bp.mobile, `
    font-size: 14px;
  `)}

  label {
    ${size('1px')}
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    position: absolute !important;    
    white-space: nowrap; /* added line */
  }
`;

const StyledField = styled.input`
  ${size('52px', '100%')}
  background: transparent;
  border: 1px solid var(--black);
  border-radius: 0;
  color: var(--black);
  font-family: var(--font-primary);
  font-size: ${rem(16)};
  line-height: 52px;
  padding: 0 15px;
  -webkit-appearance: none;
  
  &:focus {
    outline: 2px dotted var(--black);
  }

  &::placeholder {
    color: var(--black);
  }
`;

const StyledTextArea = styled.textarea`
  background: transparent;
  border: 1px solid var(--black);
  border-radius: 0;
  color: var(--black);
  font-family: var(--font-primary);
  font-size: ${rem(16)};
  line-height: 1.2;
  min-height: 104px;
  padding: 15px;
  resize: none;
  width: 100%;
  -webkit-appearance: none;

  &:focus {
    outline: 2px dotted var(--black);
  }

  &::placeholder {
    color: var(--black);
  }
`;

const StyledError = styled.span`
  display: block;
  font-size: ${rem(11)};
  font-weight: 500;
  margin-left: 10px;
  margin-top: 3px;
  position: absolute;
`;

const FormSubmit = styled.button`
  ${size('52px', '100%')}
  background: var(--black);
  color: var(--white);
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: rem(16);
  text-align: center;

  &:focus {
    outline: 2px dotted var(--black);
  }
`;

const HeroForm = ({ setFormComplete }) => (
  <Formik
    initialValues={{ 
      'form-name': 'contact',
      userName: '',
      email: '',
      message: '',
    }}
    validate={values => {
      const errors = {};
      if (!values.userName) {
        errors.userName = 'It\'d be nice to know your name!';
      }
      if (!values.email) {
        errors.email = 'I\'m going to need your email address.';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'Well that isn\'t an email address, is it?';
      }
      if (!values.message) {
        errors.message = 'Are you just going to send me a blank message?';
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      netlifySubmit(values)
        .then(() => {
          setFormComplete(true);
          setSubmitting(false);
          resetForm();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    }) => (
        <StyledForm
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        name="contact"
        onSubmit={handleSubmit}
      >
        <VisuallyHidden>
          <input
            name="form-name"
            value={values['form-name']}
            type="hidden"
            readOnly
          />
          <label htmlFor="bot-field">
            Don&apos;t fill this out if you&apos;re human:
            <input name="bot-field" />
          </label>
        </VisuallyHidden>

        <FormInput>
          <label htmlFor="name">Full Name</label>
          <StyledField
            type="text"
            id="name"
            name="userName"
            placeholder="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.userName}
          />
          {errors.userName && touched.userName &&
            <StyledError>{errors.userName}</StyledError>
          }
        </FormInput>

        <FormInput>
          <label htmlFor="email">Email Address</label>
          <StyledField 
            type="email"
            id="email"
            name="email"
            placeholder="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && touched.email &&
            <StyledError>{errors.email}</StyledError>
          }
        </FormInput>

        <FormInput>
          <label htmlFor="message">Your Message</label>
          <StyledTextArea
            rows="4"
            id="message"
            name="message"
            placeholder="message"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
          />
          {errors.message && touched.message &&
            <StyledError>{errors.message}</StyledError>
          }
        </FormInput>

        <FormSubmit type="submit" disabled={isSubmitting}>
          submit
        </FormSubmit>
      </StyledForm>
    )}
  </Formik>
);

export default HeroForm;
