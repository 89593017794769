import React from 'react';
import GlobalStyle from './styles/global-styles';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import Resume from './pages/resume';
import Header from './components/Header';

function App() {
  return (
    <Router>
      <div className="App">
        <GlobalStyle />
        <Header />
      </div>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/resume' element={<Resume/>} />
      </Routes>
    </Router>
  );
}

export default App;
