import React from 'react';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: 0,
  left: '-99px',
  clip: 'rect(0, 0, 0, 0)',
  pointerEvents: 'none',
};

const VisuallyHidden = ({ children, htmlTag = 'div' }) => {
  const Hidden = htmlTag;

  return (
    <Hidden style={style}>
      {children}
    </Hidden>
  );
};

VisuallyHidden.propTypes = {
  children: PropTypes.node,
  htmlTag: PropTypes.string,
};

export default VisuallyHidden;
